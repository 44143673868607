import React from 'react';

const ScenarioForm = ({
  title,
  values,
  handleValuesChange,
  estimatedVolume,
  estimatedValue,
}) => {
  const inputFields = [
    { label: 'Awareness %', name: 'awareness' },
    { label: 'Purchase Intention %', name: 'purchaseIntention' },
    { label: 'Distribution %', name: 'distribution' },
    { label: 'Frequency', name: 'frequency' },
    { label: 'NSP €', name: 'nsp' },
  ];

  return (
    <div>
      <h3 style={{ fontWeight: 400 }}>{title}</h3>
      {inputFields.map(({ label, name }) => (
        <div style={{ display: 'contents' }} key={name}>
          <label>{label}</label>
          <br />
          <input
            type="number"
            name={name}
            min="0"
            max="100"
            value={values[name]}
            onChange={handleValuesChange}
            className={
              values[name] >= 0 && values[name] <= 100 ? 'valid' : 'invalid'
            }
          />
          <br />
          <br />
        </div>
      ))}
      <label style={{ fontWeight: 200 }}>
        Estimated Volume :{' '}
        {typeof estimatedVolume === 'number'
          ? estimatedVolume.toFixed(2)
          : 'N/A'}
      </label>
      <br />
      <br />
      <label style={{ fontWeight: 200 }}>
        Estimated Value :{' '}
        {typeof estimatedValue === 'number'
          ? estimatedValue.toFixed(2)
          : 'N/A'}
      </label>
    </div>
  );
};

export default ScenarioForm;
