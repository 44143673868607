// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Calculator from './Calculator';

import './App.css';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Calculator />} />
          {/* Other routes can be defined here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
